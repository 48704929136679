import { Card, CardContent, styled } from "@mui/material";

const RootStyle = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  borderRadius: 5
}));
export default function GPLXAds({ ...other }) {
  return (
    <RootStyle>
      <CardContent sx={{ m: 0, p: 0, paddingBottom: "0 !important" }}>
        <a href="https://onthibanglaixe.net/" target="_blank" rel="noreferrer">
          <img src="/image/gplx.jpg" width={300}  {...other} alt="" />
        </a>
      </CardContent>
    </RootStyle>
  );
}