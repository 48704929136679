/* eslint-disable react/prop-types */
// @mui
import { Card, Stack, Typography, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// utils
import { fNumber, fPercent } from '../../utils/formatNumber';
// components
import Link from 'next/link';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import Label from '../../components/Label';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------
export function TagItemStyle1({ tag, index }) {
  let { title, exerciseNo = 0, acceptedNo = 0, answerNo = 0, featureImage } = tag;

  return (
    <Link href={`/tag/${tag.slug}`} passHref>
      <Card sx={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', alignItems: 'center', p: 1, mt: 1, mb: 3, ml: { sm: index % 4 === 0 ? 0 : 1 } }}>
        <Stack sx={{ flex: 1, ml: 1 }}>
          <Typography variant="subtitle2" gutterBottom>{title}</Typography>
          <Stack direction="row" alignItems="center">
            <IconWrapperStyle>
              <Iconify width={16} height={16} icon={'eva:trending-up-fill'} />
            </IconWrapperStyle>
            <Typography variant="subtitle2" component="span">
              {fPercent(acceptedNo * 100 / answerNo)}
            </Typography>
            <Typography variant="body2" component="span" noWrap sx={{ color: 'text.secondary' }}>
              &nbsp;đúng&nbsp;
            </Typography>
            <Typography variant="subtitle2" component="span">
              / {fNumber(exerciseNo)} câu
            </Typography>
          </Stack>
        </Stack>
        <Image src={featureImage} sx={{ height: 90, maxWidth: 90, borderRadius: 0.5 }} alt={title} />
      </Card>
    </Link>
  );
}

//-------STYLE 2---------
export function TagItemStyle2({ tag, index }) {
  let { title, exerciseNo = 0, acceptedNo = 0, answerNo = 0, featureImage } = tag;

  return (
    <Link href={`/tag/${tag.slug}`} passHref>
      <Card sx={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', alignItems: 'center', p: 0, mt: 1, mb: 3, ml: { sm: index % 4 === 0 ? 0 : 1 } }}>
        <Image src={featureImage} sx={{ height: 80, width: 80 }} alt={title} />
        <Stack sx={{ flex: 1, ml: 1 }}>
          <Typography variant="subtitle2" gutterBottom>{title}</Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle2" component="span">
              {fPercent(acceptedNo * 100 / answerNo)}
            </Typography>
            <Typography variant="body2" component="span" noWrap sx={{ color: 'text.secondary' }}>
              &nbsp;đúng&nbsp;
            </Typography>
            <Typography variant="subtitle2" component="span">
              / {fNumber(exerciseNo)} câu
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Link>
  );
}

// ----------------------------------------------------------------------
export function TagItemStyle3({ tag }) {
  let { title, exerciseNo = 0 } = tag;
  let theme = useTheme();

  return (
    <Link href={`/tag/${tag.slug}`} passHref>
      <Card key={tag.id} sx={{
        px: 2, py: 0.5, cursor: 'pointer', display: 'inline-block',
        '&:hover': {
          background: theme.palette.background.neutral
        },
      }}>
        <Typography variant='subtitle2' component="span">
          {title} {" "}
          <Label variant={'filled'} color={"default"} >
            {fNumber(exerciseNo)}
          </Label>
        </Typography>
      </Card>
    </Link>
  );
}
