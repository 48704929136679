/* eslint-disable react/prop-types */
import { Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// layouts
import dynamic from 'next/dynamic';
import Layout from '../layouts';
// components
import NextLink from 'next/link';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import GPLXAds from '../components/GPLXAds';
import Iconify from '../components/Iconify';
import JMasterAds from '../components/JMasterAds';
import Page from '../components/Page';
import useLocales from '../hooks/useLocales';
import useSettings from '../hooks/useSettings';
import { getSchools } from '../redux/slices/hackerthon/hackerthon.tag';
import ExerciseList from '../sections/exercise/ExerciseList';
import Welcome from '../sections/home/Welcome';
import { TagItemStyle1 } from '../sections/tag/TagItem';
import { findSliderAPI } from '../service/hackerthon/hackerthon.slider.service';
const BannerSlider = dynamic(() => import('../sections/home/BannerSlider'));
const SidebarRankingUser = dynamic(() => import('../sections/ranking/SidebarRankingUser'));

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(10),
  paddingTop: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(15),
  },
}));

// ----------------------------------------------------------------------

HomePage.getLayout = function getLayout(page) {
  return <Layout variant="main">{page}</Layout>;
};

// ----------------------------------------------------------------------

export default function HomePage({ sliders }) {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <Page title={translate("index.title")} description={translate('index.description')} >
      <RootStyle >
        <Container maxWidth={themeStretch ? 'xl' : 'lg'}>
          <BannerSection sliders={sliders} />
          <Grid container spacing={1} >
            <Grid item xs={12} md={9}>
              <SchoolTags />
              {/* <PlanTags /> */}
              <Divider />
              <ExerciseList />
              <JoinTeam />
            </Grid>
            <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' } }} >
              <Stack spacing={2}>
                <SidebarRankingUser />
                {/* <SidebarSolution /> */}
                <JMasterAds />
                <GPLXAds />
                {/* <ExnessVideoAds /> */}
                {/* <CardAds /> */}
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </Page >
  );
}

function BannerSection({ sliders }) {
  return (
    <Grid container spacing={3} mb={6}>
      <Grid item xs={12} md={4}>
        <Welcome />
      </Grid>
      <Grid item xs={12} md={8}>
        <BannerSlider sliders={sliders} />
      </Grid>
    </Grid>);
}

const JoinTeam = () => (
  <Container sx={{ pt: 5, textAlign: 'center' }}>
    <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.error' }}>
      <Iconify width={16} height={16} icon={'mdi:heart'} sx={{ color: 'red' }} /> Made with Love <Iconify width={16} height={16} icon={'mdi:heart'} sx={{ color: 'red' }} />
    </Typography>
    <Typography variant="h2" sx={{ mb: 3, textTransform: 'capitalize' }}>
      Lập Trình với Giải Thuật
    </Typography>
    <Typography sx={{ mx: 'auto', maxWidth: 630, color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'), }} >
      Hãy chung tay tạo một cộng đồng lập trình Việt Nam lớn mạnh. Viết bài cho chúng tôi, xin gửi về <Link href='mailto:trungtamjava.com@gmail.com?subject=hackerthon'>trungtamjava.com@gmail.com</Link>
    </Typography>
  </Container>
);


export function SchoolTags() {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const { schools } = useSelector(state => state.hackerthonTag);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getSchools());
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const settings = {
    speed: 500,
    arrows: false,
    dots: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
    ]
  };

  return (
    <Grid container spacing={2} mb={1}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" >
          <Typography variant="h5">Trường học</Typography>
          <NextLink href={`/explorer/`} passHref>
            <Link href='#'>
              <Typography variant="caption">{translate("button.viewall")}</Typography>
            </Link>
          </NextLink>
        </Stack>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Slider {...settings}>
          {schools?.map((tag, index) => (
            <TagItemStyle1 key={tag.id} tag={tag} index={index} />
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
}

export async function getStaticProps() {
  const search = {
    page: 0, size: 20, value: '%%', orders: [{ order: 'asc', property: 'id' }], filterBys: { status: 'ACTIVE' }
  };
  const { code, data: sliders } = await findSliderAPI(search);

  if (code !== '200') {
    return {
      props: {
        sliders: []
      },
    };
  }

  return {
    props: {
      sliders,
    },
  };
}

