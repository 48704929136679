import { Card, CardContent, styled } from "@mui/material";

const RootStyle = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  borderRadius: 5
}));
export default function JMasterAds({ ...other }) {
  return (
    <RootStyle>
      <CardContent sx={{ m: 0, p: 0, paddingBottom: "0 !important" }}>
        <a href="https://trungtamjava.com/cac-khoa-hoc-lap-trinh-tai-trung-tam-java/" target="_blank" rel="noreferrer">
          <img src="/image/jmaster_path.jpg" width={300} height={300} {...other} alt="" />
        </a>
      </CardContent>
    </RootStyle>
  );
}