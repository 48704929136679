// @mui
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import Image from '../../components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 5,
  textAlign: 'center',
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : 'none',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

export default function Welcome() {
  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 }
        }}
      >
        <Typography gutterBottom variant="h4">
          Hệ thống,
          <br />THI GIẢI THUẬT
          <br />& TRẮC NGHIỆM
        </Typography>

        <Typography variant="body2" sx={{ pb: { xs: 1, xl: 2 }, maxWidth: 480, mx: 'auto', mt: 2 }}>
          Những câu hỏi ôn tập và đề thi thuật toán cập nhật hàng tháng. Bạn có thể tham gia ngay trong ngày hôm nay miễn phí...
        </Typography>
      </CardContent>

      <Image
        alt="hackerthon"
        src={`/image/login_banner.png`}
        sx={{
          p: 1,
          width: 300,
          margin: { xs: 'auto', md: 'inherit' },
          display: { xs: 'none', sm: 'block' }
        }}
      />
    </RootStyle>
  );
}
